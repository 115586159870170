import { getMockPageData, getMockValue, mockPagingInfo } from '@f1/shared/src/data/_mockDataHelpers';
import { isEmpty, transformData } from '@f1/shared/src/_helpers';
import { ticketRequestDropdownFields, ticketStatusList } from '@f1/shared/src/_formFields';
import ticketsTemplate from './templates/ticketsTemplate';
import { getMockRelationshipList } from '../_mockDataHelpers';

const getMockTickets = (options) => {
  const { guid, relationshipList } = options || {};
  const mockRelationshipList = relationshipList ?? getMockRelationshipList();
  const mockRelationship = mockRelationshipList[0] || {};
  const mockStatusList = ticketStatusList.map(item => item.value);
  const mockTickets = [...mockStatusList, ...Array.from({ length: 20 })].map((item, index) => {
    const mockUser = getMockValue({ type: 'user', userType: 'external' });
    const mockTicketRequestList = ticketRequestDropdownFields({ userType: 'partner' }).map(t => t.title);
    const mockTicketTitle = getMockValue({ type: 'list', list: mockTicketRequestList });
    const mockMerchantGuid = guid ?? getMockValue({ type: 'guid' });
    const mockMerchantDba = getMockValue({ type: 'businessName' });
    const mockMerchantLegal = getMockValue({ type: 'businessName' });
    const mockRelationshipId = mockRelationship.value;
    const mockRelationshipName = mockRelationship.title;
    const mockTicket = {
      description: `PORTAL USER REQUEST: CONFIRM USER OWNS MERCHANT OR PARTNER.\nRelationship Id: ${mockRelationshipId}\nRelationship Name: ${mockRelationshipName}\nMerchant Id: ${mockMerchantGuid}\nDba Name: ${mockMerchantDba}\nLegal Name: ${mockMerchantLegal}\n${mockTicketTitle}: ${getMockValue({ type: 'description' })}`,
      title: `PORTAL USER REQUEST: ${mockTicketTitle}`,
      externalWatchers: [mockUser.email],
      ticketId: `${index}`,
      ticketBusinessCode: getMockValue({ min: 1, max: 3000, stringify: true }),
      requestedByPortalUser: {
        portalUserId: getMockValue({ type: 'guid' }),
        email: mockUser.email
      },
      merchant: {
        merchantGuid: mockMerchantGuid,
        dbaName: mockMerchantDba,
        legalName: mockMerchantLegal
      },
      relationship: {
        relationshipId: mockRelationshipId,
        name: mockRelationshipName
      },
      status: item || getMockValue({ type: 'list', list: mockStatusList }),
      createdTimestamp: getMockValue({ type: 'isoDate' }),
      requiredCompletionTime: getMockValue({ type: 'isoDate' })
    };
    return mockTicket;
  });
  return mockTickets;
};

export const mockTicketsGet = getMockTickets();

export const mockTicketsGetFormatted = transformData({
  data: { ticketList: mockTicketsGet },
  toSchema: 'frontend',
  template: ticketsTemplate,
  version: '1.0'
});

export const mockTicketsPutRes = (body, params, options) => {
  const { guid = 'mockGuid' } = options;
  return { [guid]: { ticketId: getMockValue('string') } };
};

export const mockTicketsRefundRequestPutRes = (body, params, options) => {
  const { guid = 'mockGuid' } = options;
  return { [guid]: { refundRequestIdList: ['mock-refund-request-id-1', 'mock-refund-request-id-2'] } };
};

export const mockPagedTicketsGet = (body, params, options) => {
  const { guid } = options || {};
  const {
    uncompletedOnly,
    totalNumberOfRecords = mockPagingInfo.totalNumberOfRecords,
    pageIndex = mockPagingInfo.pageIndex
  } = params || {};
  const mockAllData = !isEmpty(guid) ? getMockTickets(options) : mockTicketsGet;
  const openTicketStatuses = ticketStatusList.filter(item => item.isOpen).map(item => item.value);
  const filteredAllData = mockAllData.filter(
    item => (uncompletedOnly ? openTicketStatuses.includes(item.status) : true)
  );
  const pageData = getMockPageData({
    allData: filteredAllData,
    pageIndex,
    pageSize: 25,
    optionsCopy: { ...params, pageSize: 25 }
  });
  const mockPagedData = {
    ticketList: pageData,
    pagingInfo: {
      totalNumberOfRecords,
      pageIndex,
      pageSize: 25
    }
  };
  return !isEmpty(guid) ? { [guid]: mockPagedData } : mockPagedData;
};
